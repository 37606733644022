/**
 * App
 */

import './utils/closest.polyfill.js';
import './utils/matches.polyfill.js';

import navbar from './components/navbar';

document.addEventListener('DOMContentLoaded', function() {
  navbar();
});